import Vue from 'vue';
import { Group } from '@/shared/ui/Toast.vue';

export default function copyAddress(address: string) {
  navigator.clipboard.writeText(address);

  Vue.notify({
    group: Group.GLOBAL,
    title: '',
    type: 'success',
    text: 'Copied to clipboard',
  });
}
