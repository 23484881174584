import { computed, onMounted, ref, watch } from '@vue/composition-api';
import store from '@/store';
import wretch from 'wretch';
import { Wallet } from '@/features/WalletButton';

export interface ProfileWallet {
  address: string
  domain: string
  id: number
  active: string
}

const BASE_URL = `${process.env.VUE_APP_API_LOCATION}/api/v1/wallets`;

export default function useWallets() {
  const wallets = ref<ProfileWallet[]>([]);
  const loading = ref(false);

  const accessToken = computed<string>(() => store.getters.accessToken);
  const activeWallet = computed<Wallet>(() => store.getters.activeWallet);

  async function deleteWallet(wallet: ProfileWallet) {
    try {
      await wretch(`${BASE_URL}/${wallet.id}/`)
        .auth(`Bearer ${accessToken.value}`)
        .delete()
        .res();

      console.log(activeWallet.value, wallet.address);

      if (activeWallet.value && activeWallet.value.accountAddress === wallet.address) {
        localStorage.removeItem('activeWallet');
        store.commit('updateActiveWallet', null);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getWallets() {
    try {
      const api = `${BASE_URL}/wallets/`;
      loading.value = false;
      const data: ProfileWallet[] = await wretch(api)
        .auth(`Bearer ${accessToken.value}`)
        .get()
        .json();
      wallets.value = data;
    } catch (err) {
      console.log(err);
    } finally {
      loading.value = false;
    }
  }

  watch(activeWallet, () => {
    getWallets();
  });

  onMounted(() => {
    getWallets();
  });

  return {
    wallets,
    loading,
    getWallets,
    deleteWallet,
  };
}
